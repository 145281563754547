@import '../../assets/scss/variables.scss';

.activity-history-container {
  flex: 0 0 460px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  border-radius: $border-radius-primary;
  border: 1px solid $border-gray-color;
  overflow: hidden;

  &.collapsed {
    flex-basis: auto;
    border: none;

    .activity-history {
      &__header {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        row-gap: 8px;
      }
      &__title {
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        color: $gray-color-tertiary;
      }
      &__body {
        display: none;
      }
      &__no-data {
        display: none;
      }
    }
  }

  .activity-history {
    &__header {
      padding: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__body {
      padding-bottom: 20px;
      overflow: auto;
    }

    &__title {
      font-size: 16px;
      font-weight: 800;
      line-height: 1;
    }

    &__collapse-button {
      padding: 0;
      width: 24px;
      height: 24px;
      color:$gray-color-tertiary;
      &:hover {
        color: $gray-color-secondary;
      }
      svg {
        width: 24px;
        height: 24px;
      }
    }

    &__list {
      margin-left: 20px;
      padding: 0 20px 0 0;
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      border-left: 1px dashed $border-gray-color;
    }

    &__next-data-loader {
      width: 100%;
      padding: 10px 0;
      display: flex;
      justify-content: center;
      svg {
        width: 32px;
        height: 32px;
      }
    }

    &__no-data {
      padding: 10px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 5px;

      svg {
        width: 24px;
        height: 24px;
        color: $info-color-1;
      }

      span {
        font-size: 14px;
        font-weight: 500;
        color: $gray-color-2;
      }
    }
  }

  @media (max-width: $md-2) {
    flex: 1 1 300px;

    &.collapsed {
      flex: none;
      min-height: auto;
      border: 1px solid $border-gray-color;

      .activity-history {
        &__header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }
        &__title {
          font-size: 16px;
          font-weight: 800;
          line-height: 1;
          color: $gray-color-body;
          text-transform: none;
        }
        &__body {
          display: none;
        }
        &__no-data {
          display: none;
        }
      }
    }
  }
}
