@import '../../../assets/scss/variables.scss';

.custom-cell__labeled-tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  max-width: 300px;

  .labeled-tag-item {
    width: max-content;
    max-width: 100%;
    padding: 3px 8px;
    position: relative;
    color: $gray-color-secondary;
    font-size: 14px;
    border-radius: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
