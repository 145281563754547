@import '../../../assets/scss/variables.scss';

.activity-history-item {
  position: relative;
  padding-left: 28px;

  .event {
    &__time-point {
      position: absolute;
      top: 50%;
      left: -1px;
      padding: 1px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 3px;
      background-color: $color-white;
      transform: translate(-50%, -50%);
      z-index: 5;

      .time {
        font-size: 10px;
        font-weight: 500;
        color: $gray-color-tertiary;
      }

      .point {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: $gray-color-secondary;
      }
    }

    &__content {
      display: flex;
      column-gap: 16px;
    }

    &__image-container {
      flex: 0 0 173px;
      height: 110px;
    }

    &__details {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      row-gap: 4px;
      overflow: hidden;
    }

    &__description {
      margin-bottom: 2px;
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__info {
      color: $gray-color-tertiary;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  @media (max-width: $md-2) {
    .event {
      &__image-container {
        flex: 0 0 118px;
        height: 75px;
      }

      &__info {
        color: $gray-color-tertiary;
        overflow: visible;
        word-break: break-all;
      }
    }
  }
}

